import { AppSettingsService } from 'src/app/settings/services/app-settings.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from 'src/app/e-commerce/settings/settings.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
})
export class SocialLoginComponent implements OnInit {
  clientId: string = '';
  googleServiceJsonFile: string = '';
  isEmailChecked: boolean = false;
  isFaceBookChecked: boolean = false;
  isGoogleChecked: boolean = false;
  isGoogleServiceJsonUploading: boolean = false;
  isPlistUploading: boolean = false;
  plistFile: string = '';
  reversedId: string = '';
  sha_hashkey: string = '';
  social!: FormGroup;

  constructor(
    private appSettingsService: AppSettingsService,
    private builderService: BuilderService,
    private db: DatabaseService,
    private fb: FormBuilder,
    private http: HttpClient,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.social = this.fb.group({
      plist_filepath: [''],
      google_service_json: [''],
    });
    this.getApplicationSettingsData();
  }

  saveToApplicationSettings() {
    const projectId = this.builderService.selectedProject;

    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (currentSettings: any) => {
        const updatedSettings = {
          ...currentSettings,
          clientId: this.clientId,
          email_login: this.isEmailChecked,
          faceBook_login: this.isFaceBookChecked,
          google_login: this.isGoogleChecked,
          reversedId: this.reversedId,
          sha_hashkey: this.sha_hashkey,
          plistFile: this.plistFile,
          googleServiceJsonFile: this.googleServiceJsonFile
        };

        this.appSettingsService
          .setApplicationSettings(projectId, updatedSettings)
          .subscribe(
            () => {
              this.appSettingsService
                .setWPApplicationSettings(
                  this.builderService.selectedProject,
                  updatedSettings
                )
                .subscribe(() => {
                  console.log(
                    'Social login data saved successfully',
                    updatedSettings
                  );
                });
            },
            (error: any) => {
              console.error('Error updating social login data', error);
            }
          );
      },
      (error: any) => {
        console.error('Error saving social login data', error);
      }
    );
  }

  getApplicationSettingsData() {
    const projectId = this.builderService.selectedProject;
    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (data: any) => {
        if (data) {
          this.clientId = data.clientId;
          this.isEmailChecked = data.email_login ?? false;
          this.isFaceBookChecked = data.faceBook_login ?? false;
          this.isGoogleChecked = data.google_login ?? false;
          this.reversedId = data.reversedId;
          this.sha_hashkey = data.sha_hashkey;
          this.plistFile = data.plistFile;
          this.googleServiceJsonFile = data.googleServiceJsonFile;
        } else {
          console.error('No data found');
        }
      },
      (error: any) => {
        console.error('Error loading data', error);
      }
    );
  }

  reverseClientId() {
    if (this.clientId) {
      const parts = this.clientId.split('.');
      const newReversedId = `${parts[parts.length - 1]}.${parts
        .slice(0, -1)
        .reverse()
        .join('.')}`;
      this.reversedId = newReversedId;
    }
  }

  processFile(fileInput: any, type: string, name: string) {
    const file: File = fileInput.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService.uploadFile(file, this.builderService.selectedProject, type, name).subscribe(
        (res) => {
          this.builderService.showUpdateProgress = false;
          if (name === 'google_service_json') {
            this.googleServiceJsonFile = file.name;
          } else if (name === 'google_service_plist') {
            this.plistFile = file.name;
          }
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading file, please contact support");
        })
    });

    reader.readAsDataURL(file);
  }

  clearFileName(name: string) {
    if (name === 'google_service_json') {
      this.googleServiceJsonFile = '';
    } else if (name === 'google_service_plist') {
      this.plistFile = '';
    }
  }

  googleToggle(event: any): void {
    this.isGoogleChecked = event.checked;
  }

  emailToggle(event: any): void {
    this.isEmailChecked = event.checked;
  }

  faceBookToggle(event: any): void {
    this.isFaceBookChecked = event.checked;
  }

}
