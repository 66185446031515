import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductsService } from '../../e-commerce/products/products.service';

@Component({
  selector: 'app-product-translations',
  templateUrl: './product-translations.component.html',
  styleUrls: ['./product-translations.component.scss']
})
export class ProductTranslationsComponent implements OnInit {

  ELEMENT_DATA: any[] = [];
  languages: { title: string }[] = [];
  pagedTableData: any[] = [];
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  displayedColumns: string[] = [];
  productTranslations!: FormGroup;
  productProgress: boolean = false;

  constructor(
    private db: DatabaseService,
    private builderService: BuilderService,
    private productService: ProductsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.productTranslations = this.fb.group({
      languages: this.fb.array([])
    });
    Promise.all([this.loadLanguages(), this.getProductNames()])
      .then(() => {
        this.getTranslate();
        this.updatePageData(0);
        this.updateDisplayedColumns();
      })
      .catch(error => console.error("Error initializing data:", error));
  }

  updatePageData(pageIndex: number) {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedTableData = this.ELEMENT_DATA.slice(startIndex, endIndex);
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.updatePageData(event.pageIndex);
  }

  loadLanguages(): Promise<void> {
    return new Promise((resolve, reject) => {
      const project_id = this.builderService.selectedProject;
      const path = `/projects/${project_id}/translations/languages`;
      this.db.getDatabase(path).subscribe({
        next: (languagesData: any) => {
          this.languages = this.formatLanguages(languagesData);
          this.sortLanguages('English');
          resolve();
        },
        error: (error) => {
          console.error('Error fetching languages:', error);
          reject(error);
        }
      });
    });
  }

  getProductNames(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.productService.getProducts().subscribe({
        next: (productsObject) => {
          this.ELEMENT_DATA = Object.values(productsObject);
          console.log('Products loaded successfully.');
          resolve();
        },
        error: (error) => {
          console.error('Error fetching products:', error);
          reject(error);
        }
      });
    });
  }

  formatLanguages(languagesData: any): any[] {
    return Object.keys(languagesData).map(key => ({ title: key, ...languagesData[key] }));
  }

  sortLanguages(primaryLanguage: string): void {
    const index = this.languages.findIndex(lang => lang.title === primaryLanguage);
    if (index > -1) {
      const [primaryLang] = this.languages.splice(index, 1);
      this.languages.unshift(primaryLang);
    }
  }

  getTranslate(): void {
    const project_id = this.builderService.selectedProject;
    this.ELEMENT_DATA.forEach(product => {
      this.languages.forEach(lang => {
        const path = `/projects/${project_id}/translations/languages/${lang.title}/Products/${product.productName}`;
        this.db.getDatabase(path).subscribe({
          next: (response: any) => {
            if (response && response.value) {
              product[lang.title] = response.value;  // Update the product object with the fetched translation
            }
          },
          error: (error) => console.error(`Failed to load translation for ${product.productName} in ${lang.title}:`, error)
        });
      });
    });
  }

  saveTranslation(product: any, langTitle: string): void {
    this.productProgress = true;
    const project_id = this.builderService.selectedProject;
    const value = langTitle === 'English' ? product.productName : product[langTitle] || '';
    const translationObject = { value: value };
    const uniquePath = `/projects/${project_id}/translations/languages/${langTitle}/Products/${product.productName}`;
    this.db.setDatabase(uniquePath, translationObject).subscribe({
      next: (response) => {
        console.log(`Translation for ${product.productName} in ${langTitle} saved:`, response);
        this.productProgress = false;
        product.editing = null;
      },
      error: (error) => {
        console.error(`Failed to save translation for ${product.productName} in ${langTitle}:`, error);
        this.productProgress = false;
      }
    });
  }

  saveChanges(): void {
    this.ELEMENT_DATA.forEach(product => {
      this.languages.forEach(lang => {
        if (lang.title !== 'English') {
          this.saveTranslation(product, lang.title);
        }
      });
    });
  }

  toggleEdit(product: any, langTitle: string): void {
    this.ELEMENT_DATA.forEach(p => {
      if (p !== product) {
        p.editing = null;
      }
    });
    product.editing = langTitle;
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = this.languages.map(lang => lang.title);
  }
}
