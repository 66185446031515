<div class="SocialContainer">
  <mat-accordion>
    <mat-expansion-panel disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p>
          {{ "Email" | translate }}
          </p>
          <mat-slide-toggle
            (change)="emailToggle($event)"
            (click)="$event.stopPropagation()"
            [checked]="isEmailChecked"
            color="primary"
          >
          </mat-slide-toggle>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p>
          {{ "Facebook" | translate }}
          </p>
          <mat-slide-toggle
            (change)="faceBookToggle($event)"
            (click)="$event.stopPropagation()"
            [checked]="isFaceBookChecked"
            color="primary"
          >
          </mat-slide-toggle>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p>
          {{ "Google Plus" | translate }}
          </p>
          <mat-slide-toggle
            (change)="googleToggle($event)"
            (click)="$event.stopPropagation()"
            [checked]="isGoogleChecked"
            color="primary"
          >
          </mat-slide-toggle>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="GooglePlus">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ "Google Plus Client ID" | translate }}</mat-label>
            <input
              (ngModelChange)="reverseClientId()"
              [(ngModel)]="clientId"
              matInput
              placeholder="{{ 'Enter Client ID' | translate }}"
            />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ "Google Plus Reversed ID" | translate }}</mat-label>
            <input
              [(ngModel)]="reversedId"
              matInput
              placeholder="{{ 'Enter Reversed ID' | translate }}"
            />
          </mat-form-field>
        </div>

        <div *ngIf="sha_hashkey">
          <mat-form-field appearance="outline">
            <mat-label>{{ "SHA Hash Key" | translate }}</mat-label>
            <input matInput [value]="sha_hashkey" readonly />
          </mat-form-field>
        </div>

      </div>
      <div class="fileUploadContainer">
        <div class="fileInput">
          <label class="fileUpload" for="google_service_plist">{{'Google Service Plist' | translate }}
            <input type="file" formControlName="google_service_plist" id="google_service_plist" name="google_service_plist"
              accept="*" (change)="this.processFile($event, 'application/octet-stream', 'google_service_plist')">
          </label>
          <span *ngIf="plistFile">{{ plistFile }}</span>
          <button
            *ngIf="plistFile"
            mat-icon-button
            (click)="clearFileName('google_service_plist')"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-spinner *ngIf="isPlistUploading" diameter="24"></mat-spinner>
        </div>
        <div class="fileInput">
          <label class="fileUpload" for="google_service_json">{{'Google Service Json' | translate }}
            <input type="file" formControlName="google_service_json" id="google_service_json" name="google_service_json"
              accept="application/JSON" (change)="this.processFile($event, 'application/json', 'google_service_json')">
          </label>
          <span *ngIf="googleServiceJsonFile">{{ googleServiceJsonFile }}</span>
          <button
            *ngIf="googleServiceJsonFile"
            mat-icon-button
            (click)="clearFileName('google_service_json')"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-spinner
            *ngIf="isGoogleServiceJsonUploading"
            diameter="24"
          ></mat-spinner>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <form
    (ngSubmit)="saveToApplicationSettings()"
    [formGroup]="social"
    id="social"
  ></form>
</div>
