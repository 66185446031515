import { BuilderService } from 'src/app/builder-services/builder.service';
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsService } from 'src/app/e-commerce/products/products.service';
export interface Product {
  imageUrl?: string;
  productName: string;
  productSKU: string;
  productStock: number;
  salePrice?: string;
  regularPrice: string;
  date?: string;
  checked?: boolean;
}
@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss'],
})
export class PointsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  categories: any[] = [];
  dataSource = new MatTableDataSource<any>([]);
  searchText: string = '';
  displayedColumns: string[] = [
    'image',
    'productName',
    'productSKU',
    'productStock',
    'price',
    'date',
    'points',
  ];
  points!: FormGroup;
  products: any[] = [];
  selectedCategories: any[] = [];
  selectedProducts: MatTableDataSource<any> = new MatTableDataSource<any>();
  selectedProductSet = new Set<any>();
  isEditable = false;

  constructor(
    private builderService: BuilderService,
    private categoriesService: CategoriesService,
    private db: DatabaseService,
    private fb: FormBuilder,
    private productService: ProductsService
  ) {}

  /*
  The `checkAll` function handles the selection of all products based on the checkbox state.

  1. If the checkbox is checked:
     - Iterates through all products and sets their `checked` property to `true`.
     - Updates the `selectedProductSet` to include all products in the `dataSource`.
  
  2. If the checkbox is unchecked:
     - Iterates through all products and sets their `checked` property to `false`.
     - Clears the `selectedProductSet`.

  3. Updates the `selectedProducts` data array with the current selection from the `selectedProductSet`.
*/

  checkAll(event: any): void {
    if (event.checked) {
      this.dataSource.data.forEach((product) => (product.checked = true));
      this.selectedProductSet = new Set(this.dataSource.data);
    } else {
      this.dataSource.data.forEach((product) => (product.checked = false));
      this.selectedProductSet.clear();
    }
    this.selectedProducts.data = Array.from(this.selectedProductSet);
  }

  ngOnInit(): void {
    this.createForm();
    this.getData();
    this.loadData();
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 3000);
  }

  /*
  The `onItemChecked` function manages the selection of items based on their type (product or category) and checkbox state.

  1. Updates the `checked` property of the item based on the checkbox state (`event.checked`).

  2. For items of type `product`:
     - If checked, adds the item to the `selectedProductSet`.
     - If unchecked, removes the item from the `selectedProductSet`.
     - Updates the `selectedProducts` data array with the current selection from the `selectedProductSet`.

  3. For items of type `category`:
     - If checked, adds the item to the `selectedCategories` array.
     - If unchecked, removes the item from the `selectedCategories` array based on `categoryId`.
     - Logs the current list of selected categories to the console.
*/

  onItemChecked(item: any, event: any, type: string): void {
    item.checked = event.checked;

    if (type === 'product') {
      if (event.checked) {
        this.selectedProductSet.add(item);
      } else {
        this.selectedProductSet.delete(item);
      }
      this.selectedProducts.data = Array.from(this.selectedProductSet);
    } else if (type === 'category') {
      if (event.checked) {
        this.selectedCategories.push(item);
      } else {
        this.selectedCategories = this.selectedCategories.filter(
          (c) => c.categoryId !== item.categoryId
        );
      }
      console.log('Selected Categories:', this.selectedCategories);
    }
  }

  /*
  The `isChecked` function determines whether an item is checked based on its type (product or category).

  1. Returns `true` if the item is checked; otherwise, returns `false`.
  2. Defaults to `false` if the `item` is undefined or null.

  Note: This function currently does not differentiate between types (`product` or `category`) as it only checks the `checked` property.
*/

  isChecked(item: any, type: 'product' | 'category'): boolean {
    return item?.checked || false;
  }

  /*
  The `loadData` function retrieves and processes data for products and categories.

  1. Fetches products from the `productService`:
     - Updates `dataSource.data` with the fetched products, adding a `checked` property if not present.
     - Initializes `selectedProductSet` with products that are marked as checked.
     - Updates `selectedProducts.data` with the products in `selectedProductSet`.

  2. Fetches categories from the `categoriesService` after successfully fetching products:
     - Maps the fetched categories into an array, including a `categoryId` and a `checked` property.
     - Updates `selectedCategories` with categories that are marked as checked.

  3. Handles errors for both product and category fetch operations by logging them to the console.
*/

  loadData(): void {
    // Fetch products
    this.productService.getProducts().subscribe(
      (products: Product[]) => {
        this.dataSource.data = products.map((product: Product) => ({
          ...product,
          checked: product.checked || false,
        }));
        this.selectedProductSet = new Set(
          this.dataSource.data.filter((product) => product.checked)
        );
        this.selectedProducts.data = Array.from(this.selectedProductSet);
      },
      (error) => {
        console.error('Error fetching products:', error);
      }
    );
    this.categoriesService.getCategories().subscribe(
      (data) => {
        this.categories = Object.keys(data).map((key) => ({
          ...data[key],
          categoryId: key,
          checked: data[key].checked || false,
        }));
        this.selectedCategories = this.categories.filter((cat) => cat.checked);
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }

  /*
  The `createForm` function initializes the form structure for handling points and product configurations.

  1. Creates a form group named `points` using `FormBuilder`, with the following controls:
     - `currency_category_point`: A field for points related to currency categories.
     - `currency_product_point`: A field for points related to currency products.
     - `currency_system`: A field for specifying the currency system.
     - `enable_points`: A boolean field to enable or disable points.
     - `min_redeem`: A field for specifying the order system.
     - `order_all_products`: A field for specifying order points for all products, with a minimum value validator.
     - `order_cancelation`: A boolean field for order cancellation.
     - `order_checked_cats_points`: A field for points associated with checked categories.
     - `order_checked_products_points`: A field for points associated with checked products.
     - `order_points`: A field for specifying order points, with a minimum value validator.
     - `order_system`: A field for specifying the order system.
     - `points_system`: A field for specifying the points system.
     - `points`: A field for general points, with a minimum value validator.
     - `product_option`: A field for specifying the product option.
     - `product_points`: A field for product points, with a minimum value validator.
     - `products`: A form array for managing a list of products.
     - `redeem_currency`: A field for specifying the order system.
     - `redeem_points`: A field for specifying the order system.
     - `selectedProduct`: A field for managing the selected product.
     - `units`: A field for specifying units, with a minimum value validator.
     - `validity_date: A field for specifying the validity date of the points or offer.
*/

  createForm(): void {
    this.points = this.fb.group({
      currency_category_point: ['', Validators.required],
      currency_product_point: ['', Validators.required],
      currency_system: ['', Validators.required],
      enable_points: [false],
      min_redeem: ['', [Validators.required, Validators.min(0)]],
      order_all_products: ['', [Validators.required, Validators.min(0)]],
      order_cancelation: [false],
      order_checked_cats_points: ['', Validators.required],
      order_checked_products_points: ['', Validators.required],
      order_points: ['', [Validators.required, Validators.min(0)]],
      order_system: ['', Validators.required],
      points_system: ['', Validators.required],
      points: ['', [Validators.required, Validators.min(0)]],
      product_option: ['', Validators.required],
      product_points: ['', [Validators.required, Validators.min(0)]],
      products: this.fb.array([]),
      redeem_currency: ['', Validators.required],
      redeem_points: ['', [Validators.required, Validators.min(0)]],
      selectedProduct: ['', Validators.required],
      units: ['', [Validators.required, Validators.min(0)]],
      validity_date: ['', Validators.required],
    });
  }

  /*
  The `getData` function retrieves settings data for points based on the selected project.

  1. Constructs the database path using the `selectedProject` from `builderService` to target the specific settings endpoint.

  2. Fetches data from the database at the constructed path:
     - On successful retrieval, updates the form fields with the fetched data using `updateFormFields`.
     - Logs an error message to the console if the data fetch fails.
*/

  getData(): void {
    const basePath = `/projects/${this.builderService.selectedProject}/settings/points/setup`;

    this.db.getDatabase(basePath).subscribe(
      (data) => {
        this.updateFormFields(data);
      },
      (error) => {
        console.log('Error fetching data', error);
      }
    );
  }

  /*
  The `updateFormFields` function updates the form fields with the provided data.

  1. Uses `patchValue` to update the `points` form group with values from the `data` object:
     - Sets each field to the corresponding value from `data`, or defaults to an empty string or false if the value is not provided.

  2. Ensures that all fields in the form group are populated with either the provided data or default values.
*/

  updateFormFields(data: any): void {
    this.points.patchValue({
      currency_category_point: data.currency_category_point || '',
      currency_product_point: data.currency_product_point || '',
      currency_system: data.currency_system || '',
      enable_points: data.enable_points || false,
      min_redeem: data.min_redeem || '100',
      order_all_products: data.order_all_products || '',
      order_cancelation: data.order_cancelation || false,
      order_checked_cats_points: data.order_checked_cats_points || '',
      order_checked_products_points: data.order_checked_products_points || '',
      order_points: data.order_points || '50',
      order_system: data.order_system || 'allProductsCategories',
      points_system: data.points_system || 'byOrder',
      points: data.points || '',
      product_option: data.product_option || '',
      product_points: data.product_points || '',
      redeem_currency: data.redeem_currency || '10',
      redeem_points: data.redeem_points || '100',
      selectedProduct: data.selectedProduct || '',
      units: data.units || '',
      validity_date: data.validity_date || '30',
    });
  }

  /*
  The `saveChanges` function updates both category and product data in the database, as well as saving valid points data.

1. **Category Updates**:
   - Constructs the base path for categories using the `selectedProject` from `builderService`.
   - Iterates over each category in the `categories` array:
     - For each category:
       - Builds the path to the current category data using `category.categoryId`.
       - Fetches the current category data from the database.
       - Merges the fetched data with the updated fields:
         - Sets `checked` to the category's `checked` value or `false` if not present.
         - Sets `points` to the category's `points` value or `0` if not present.
       - Saves the updated category data back to the database at the constructed path.
       - Logs an error if fetching the current category data fails.

2. **Product Updates**:
   - Constructs the base path for products using the `selectedProject` from `builderService`.
   - Iterates over each product in `dataSource.data`:
     - Maps each product to include its `Id`, `checked`, and `points`:
       - Sets `checked` to the product's `checked` value or `false` if not present.
       - Sets `points` to the product's `points` value or `0` if not present.
     - Builds the path to the current product data using `product.Id`.
     - Fetches the current product data from the database.
     - Merges the fetched product data with the updated fields:
       - Sets `checked` to the product's `checked` value.
       - Sets `points` to the product's `points` value.
     - Saves the updated product data back to the database at the constructed path.
     - Logs an error if fetching the current product data fails.

3. **Points Form Validation and Save**:
   - Iterates over each control in the `points` form group and marks them as touched.
   - Filters out valid form controls, collecting their values into `validPointsData`.
   - If there is any valid points data:
     - Saves it to the database at the `pointsPath`.
   - If no valid points data exists, logs a warning: "No valid points data to save."
*/

  saveChanges(): void {
    const categoriesPath = `/projects/${this.builderService.selectedProject}/categories/`;
    const pointsPath = `/projects/${this.builderService.selectedProject}/settings/points/setup`;

    this.categories.forEach((category) => {
      const basePath = `${categoriesPath}${category.categoryId}`;

      // Fetch the current category data
      this.db.getDatabase(basePath).subscribe(
        (currentCategory) => {
          // Merge current data with the updated `checked` field
          const updatedCategory = {
            ...currentCategory,
            checked: category.checked || false,
            points: category.points || 0,
          };

          // Save the updated category
          this.db.setDatabase(basePath, updatedCategory).subscribe();
        },
        (error) => {
          console.error(
            `Error fetching current category ${category.categoryId}`,
            error
          );
        }
      );
    });

    // Iterate over each product and update its `checked` field
    this.dataSource.data
      .map((product) => ({
        Id: product.Id,
        checked: product.checked || false,
        points: product.points || 0,
      }))
      .forEach((product) => {
        const basePath = `projects/${this.builderService.selectedProject}/products/${product.Id}`;

        // Fetch the current product data
        this.db.getDatabase(basePath).subscribe(
          (currentProduct) => {
            const updatedProduct = {
              ...currentProduct,
              checked: product.checked,
              points: product.points,
            };
            this.db.setDatabase(basePath, updatedProduct).subscribe();
          },
          (error) => {
            console.error(
              `Error fetching current product ${product.Id}`,
              error
            );
          }
        );
      });

    Object.keys(this.points.controls).forEach((key) => {
      const control = this.points.get(key);
      if (control) {
        control.markAsTouched();
      }
    });

    // Filter out valid form controls and save only those
    const validPointsData = Object.keys(this.points.controls).reduce(
      (acc, key) => {
        const control = this.points.get(key);
        if (control && control.valid) {
          acc[key] = control.value;
        }
        return acc;
      },
      {} as { [key: string]: any }
    );

    if (Object.keys(validPointsData).length > 0) {
      this.db.setDatabase(pointsPath, validPointsData).subscribe();
    } else {
      console.warn('No valid points data to save.');
    }
  }

  applyFilter() {
    this.dataSource.filter = this.searchText.trim().toLowerCase();
  }

  editPoductPoints(element: any) {
    element.editing = true;
  }

  stopEdit(element: any) {
    element.editing = false;
  }
}
