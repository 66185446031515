import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { AppSettingsService } from '../services/app-settings.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { StepperComponent } from 'src/app/stepper/stepper.component';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})



export class ApplicationSettingsComponent implements OnInit {

  fontFamilies : any = [];
  fontWeights : any = [];
  textFontWeights : any = [];
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  public jsonFile!: File;
  public plistFile!: File;

  selectedFile!: ImageSnippet;


  applicationSettingsForm = this.formBuilder.group({
    mobile_title_family: '',
    mobile_title_font_weight: '',
    mobile_title_font_color: '',
    mobile_base_font_size: '',
    mobile_text_font_weight: '',
    mobile_primary_color: '',
    mobile_text_color: '',
    mobile_secondary_color: '',
    facebook_app_id: '',
    facebook_app_name: '',
    facebook_hash_id: '',
    gmob_android_app_id: '',
    gmob_ios_app_id: ''
  });

  applicationSettingsData: any = {};
  constructor(private formBuilder: FormBuilder,
    private appSettingsService: AppSettingsService,
    private router: Router,
    private route: ActivatedRoute,
    public builderService: BuilderService,
    //  public stepCase: StepperComponent
     ) { 
      this.fontFamilies.push({
        id: 'lato',
        name: 'Lato',
      }, {
        id: 'poppins',
        name: 'Poppins',
      }, {
        id: 'default',
        name: 'Default',
      });

    this.fontWeights.push({
        id: 'default',
        name: 'Default',
      }, {
        id: 'light',
        name: 'Light',
      }, {
        id: 'regular',
        name: 'Regular',
      }, {
        id: 'bold',
        name: 'Bold',
      });

    this.textFontWeights.push({
      id: 'default',
      name: 'Default',
    }, {
      id: 'light',
      name: 'Light',
    }, {
      id: 'regular',
      name: 'Regular',
    }, {
      id: 'bold',
      name: 'Bold',
    });

    let project_id = this.builderService.getSelectedProject();
    this.applicationSettingsData = this.appSettingsService.getApplicationSettings(project_id).subscribe((settings: any) => {
      this.applicationSettingsData = settings !== null ? settings : {};
      if( settings !== null ) {
        this.applicationSettingsForm.patchValue({ mobile_title_family: settings.mobile_title_family });
        this.applicationSettingsForm.patchValue({ mobile_title_font_weight: settings.mobile_title_font_weight });
        this.applicationSettingsForm.patchValue({ mobile_text_font_weight: settings.mobile_text_font_weight });
      }
      
    }, (err) => {
      console.log(err);
    });
    
  }

  ngOnInit(): void {

  }
  
  colorChange($event: any,field: any) {
    this.applicationSettingsData[field] = $event.target.value;
  }

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true;
    // let splash_screen = this.applicationSettingsForm.value.splash_screen;
    // /**
    //  * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
    //  */
    let newSubmittedData: any = {};
    let submittedData:any = this.applicationSettingsForm.value;
    let retrievedData = this.applicationSettingsData;
    for (var k in submittedData) {
      if (submittedData[k] != "") {
        newSubmittedData[k] = submittedData[k];
      } else {
        newSubmittedData[k] = retrievedData[k];
      }
    }

    console.log (newSubmittedData)

    this.appSettingsService.setApplicationSettings(this.builderService.selectedProject, newSubmittedData).subscribe(() => {
      // Save the WP Settings regardless of the standalone value
      this.appSettingsService.setWPApplicationSettings(this.builderService.selectedProject, newSubmittedData).subscribe(() => {
        this.builderService.showUpdateProgress = false;
      });
    });
  }

  // changeStep() {
  //   this.stepCase.steps[1].class = 'done';
  // }


  applicationSettingsNext = () => {
    this.onSubmit();
    this.router.navigate(['/templates'], { relativeTo: this.route });
  }
}
