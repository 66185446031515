import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersService } from './orders.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

export interface ordersTableColumns {
  id: string;
  date: string;
  user_data: any;
  user_email: any;
  user_id: string;
  status: string;
  total: string;
  action: string;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<ordersTableColumns>;

  ELEMENT_DATA: ordersTableColumns[] = [];

  public dataSource: any = [];
  pageLoaded = false;
  public ordersColumns: string[] = [];
  orders:any = {};

  @ViewChild(MatSort, { static: true })
  matSort!: MatSort;

  orders_dates: any = [];
  orders_data_counts: any = [];

  constructor(private route: ActivatedRoute, private ordersService: OrdersService,
    private builderService: BuilderService, private router: Router,) { }

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData = () => {
    this.ordersService.getOrders().subscribe((orders: any) => {
      this.ELEMENT_DATA = [];
      this.dataSource = orders;

      this.pageLoaded = true;
      this.builderService.showUpdateProgress = true;
      this.ordersColumns = ['id', 'date', 'user_data', 'status', 'total', 'action'];
      this.orders = orders;
      // this.dataSource.sort = this.matSort;
      this.renderOrders(this.orders).subscribe((response:any) => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.matSort;
          this.table.renderRows();
      });

    });
  }

  renderOrders = (result: any) => {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false;
      let ordersList:any = Object.values(result);
      for (const key in ordersList) {
        if (Object.prototype.hasOwnProperty.call(ordersList, key)) {
          const element = ordersList[key];

            this.ELEMENT_DATA.push(
              { 
                id: element.order_id,
                user_data: element.user_data, 
                user_email: element.user_email, 
                user_id: element.user,
                status: element.status, 
                total: element.total,
                date: element.date,
                action: '',
              },
            );
          }
        }

        this.dataSource = new MatTableDataSource<ordersTableColumns>(this.ELEMENT_DATA);
        setTimeout(() => {
          observer.next(); // Notify the observer that the operation is complete
          observer.complete(); // Complete the Observable
        }, 100);
    });
      
  }

  editOrder = ( id: string ) => {
    // let product_link = ;
    this.router.navigate([`/orders/single-order/${id}`], { relativeTo: this.route });
  }

  deleteOrder = (id: string, user_id: string) => {
    // console.log(user_id);
    // this.ordersService.removeOrder(id, user_id).subscribe((response:any) => {
    //   confirm("order deleted")
    //   this.getAllData();
    // });

    if(confirm("Are you sure to delete order " + id + ", This step is unrecoverable")) {
      this.ordersService.removeOrder(id, user_id).subscribe((response:any) => {
        this.getAllData();
      });
    }
  }
  

}
