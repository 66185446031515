import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AndroidbuildService } from 'src/app/build/android-build/androidbuild.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { MatDialog } from '@angular/material/dialog';
import { WorksDialogComponent } from '../works-dialog/works-dialog.component';
import { AppPreviewService } from './app-preview.service';
import { BlockDataService } from 'src/app/block-services/block-data.service';
import { MixpanelService } from 'src/app/tracking/mixpanel.service';
import { DiscordService } from 'src/app/builder-services/discord.service';

@Component({
  selector: 'app-preview',
  templateUrl: './app-preview.component.html',
  styleUrls: ['./app-preview.component.scss']
})
export class AppPreviewComponent implements OnInit, AfterViewInit {

  public public_key = '';
  public src:any;
  value = 0;
  error = false;
  checkingStatus = true;
  buildStart = false;
  appurl = '';
  // appurl = 'https://demo.stacksclients.com/wp-json/avaris-wc-rest/v3/';
  self_ltd = this.builderService.user_type == 'self_ltd' ? 'true' : 'false';
  constructor(public AppPreviewService: AppPreviewService,
    private sanitizer: DomSanitizer,
    private androidService: AndroidbuildService,
    public builderService: BuilderService,
    private router: Router,
    public dialog: MatDialog,
    private blockDataService: BlockDataService,
    private mixPanel: MixpanelService,
    private discord: DiscordService ) { 
      
    }

  
  ngOnInit(): void {
    this.builderService.previewUrl = 'https://app_preview.stacksmarket.co/?project_id='+this.builderService.getSelectedProject();
    setTimeout(() => {
      this.startPreview();
    }, 2000);
  };
  ngAfterViewInit(): void {
    
  }

  startPreview = () => {
    this.mixPanel.track('Preview Started', {
      projectid: this.builderService.getSelectedProject(),
    });
    this.discord.sendMessage( this.builderService.projects[0]?.first_name + ' previewed the app' , "Feedback" )
    .subscribe(
      () => {
        console.log('Message sent to Discord successfully!');
        // Handle success
      },
      error => {
        console.error('Error sending message to Discord:', error);
        // Handle error
      }
    );
    this.builderService.previewStarted = true;
  }
  getBuildStatus = () => {
    this.androidService.getProgress(this.builderService.getSelectedProject(), '1').subscribe((progressRes: any) => {
      this.value = progressRes.data;
      this.checkingStatus = false;
      // this.value = this.value + 10;
      this.error = false;
      if( this.value === 0 && progressRes.status !== "error") {
        this.buildStart = false;
        alert("Please build the app in order to be able to preview it");
        this.router.navigate(['/android']);
      } else {
        if ( this.value <= 100) {
          if (progressRes.status !== "error" && this.value == 100) {
            this.builderService.showUpdateProgress = true;
            let projectId: any = localStorage.getItem("selectedProject");
            let appetize_token = '';
            this.builderService.getProject(projectId).subscribe((project: any) => {
              // Cache the response
              this.builderService.user_type = project.user_type;
              this.builderService.cacheProjectReq.response = project;
              this.AppPreviewService.get_appetize_token().subscribe((response:any) => {
                appetize_token = response[0].token
                this.AppPreviewService.createApp( progressRes.android_url, project.details[0], appetize_token ).subscribe((response: any) => {
                  this.public_key = response.publicKey;
                  this.src = this.sanitizer.bypassSecurityTrustResourceUrl("https://appetize.io/embed/"+this.public_key);
                  this.builderService.showUpdateProgress = false;
                }, (err1) => {
                  console.log(err1);
                });
              }, (err2) => {
                console.log(err2);
              });
            }, (err3) => {
            
            })
          } else if (progressRes.status == "error") {
            this.error = true;
            alert("There is an error building your app, please rebuild from the build page");
            this.router.navigate(['/android']);
          }  else {
              setTimeout(() => {
                this.getBuildStatus();
              }, 5000);
              this.error = false;
          }
            this.buildStart = true;
              // alert("Please wait for the build to be finished and try again");
              // this.router.navigate(['/android']);
        } 
      }
    });
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: {name: "appPreview"},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  subscribeNow = () => {
    this.mixPanel.track('Subscribe Now', {
      projectid: this.builderService.getSelectedProject(),
    });
    this.builderService.setTour(false).subscribe((response) => {
      const dialogRef = this.dialog.open(WorksDialogComponent, {
        width: '700px',
        maxWidth: '80%',
        data: {name: "onboardingFeedback"},
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
      // this.builderService.selected_coupon_id = "o7ZwUU4C";
      this.router.navigate(['/payment']);
    });
  }

  editApp = () => {
    this.mixPanel.track('Edit App', {
      projectid: this.builderService.getSelectedProject(),
    });
    this.router.navigate(['/']);
  }
  endTour = () => {
    this.builderService.setTour(false).subscribe((response) => {
      const dialogRef = this.dialog.open(WorksDialogComponent, {
        width: '700px',
        maxWidth: '80%',
        data: {name: "onboardingFeedback"},
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    });
  }
}
