<mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
<div class="productsWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <h1>{{ 'Categories' | translate }}</h1>
      <div class="headerBtns">
        <!-- <button (click)="getCategoryNames()" class="stacksBtn blue">{{ 'Get Data' | translate }}</button> -->
      </div>
    </div>

    <table mat-table [dataSource]="pagedTableData" class="mat-elevation-z8" matSort>
      <!-- Language Columns -->
      <ng-container *ngFor="let lang of languages" [matColumnDef]="lang.title">
        <th mat-header-cell *matHeaderCellDef>{{ lang.title }}</th>
        <td mat-cell *matCellDef="let cat">
          <ng-container *ngIf="lang.title !== 'English'; else englishColumn">
            <div *ngIf="cat.editingLang !== lang.title">
              <div class="beforeEdit">
                {{ cat[lang.title] }}
                <span class="material-icons material-icons-outlined editIcon" (click)="toggleEdit(cat, lang.title)">edit</span>
              </div>
            </div>
            <div *ngIf="cat.editingLang === lang.title">
              <input type="text" class="translateTextInput" [(ngModel)]="cat[lang.title]" placeholder="Enter translation" (blur)="toggleEdit(cat, lang.title)">
            </div>
          </ng-container>
          <ng-template #englishColumn>
            <div>
              {{ cat.categoryName }}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let cat; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="ELEMENT_DATA.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
                   (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>
<form [formGroup]="categoryTranslations" id="categoryTranslations" (ngSubmit)="saveChanges()">
</form>