<mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
<div class="productsWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <div class="headerBtns">
        <!-- <button class="translateButton" mat-raised-button (click)="getProductNames()">{{ 'Get Data' | translate }}</button> -->
      </div>
    </div>

    <table mat-table [dataSource]="pagedTableData" class="mat-elevation-z8" matSort>
      <!-- Define columns for each language dynamically -->
      <ng-container *ngFor="let lang of languages" [matColumnDef]="lang.title">
        <th mat-header-cell *matHeaderCellDef>{{ lang.title }}</th>
        <td mat-cell *matCellDef="let product">
          <ng-container *ngIf="lang.title === 'English'">
            {{ product.productName }}
          </ng-container>
          <ng-container *ngIf="lang.title !== 'English'">
            <div class="beforeEdit" *ngIf="product.editing !== lang.title">
              <p>{{ product[lang.title] }}</p>
              <span class="material-icons material-icons-outlined editIcon" (click)="toggleEdit(product, lang.title)">edit</span>
            </div>
            <div *ngIf="product.editing === lang.title">
              <input class="translateTextInput" type="text" [(ngModel)]="product[lang.title]" (blur)="product.editing = null;">
            </div>
          </ng-container>
        </td>
      </ng-container>

      <!-- Header and row declarations -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="ELEMENT_DATA.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)" showFirstLastButtons>
    </mat-paginator>

  </div>
</div>
<form [formGroup]="productTranslations" id="productTranslations" (ngSubmit)="saveChanges()">
</form>
