import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrl: '../points.component.scss',
})
export class UserComponent {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'id',
    'userName',
    'email',
    'points',
    'redeem',
    'date',
    'transactionsHistory',
  ];
  points!: FormGroup;
  selectedUser: any;
  searchText: string = '';

  constructor(
    private fb: FormBuilder,
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.updateFormFields();
    this.getUsers();
  }

  updateFormFields() {
    const pointsPath = `/projects/${this.builderService.selectedProject}/settings/points/users`;

    this.db.getDatabase(pointsPath).subscribe(
      (data) => {
        if (data) {
          this.points.patchValue(data);
        }
      },
      (error) => {
        console.error('Error loading points users data:', error);
      }
    );
  }

  /*
  getUsers
    1- Define the path to the users data in the database using the selected project ID.
    2- Retrieve user data from the database using the defined path.
    3- Log the fetched user data for debugging purposes.
    4- Transform the fetched data into an array of user objects:
      4.1- Iterate over each key in the retrieved data object.
      4.2- For each user:
        4.2.1- Calculate the sum of negative points from the `points` array using filter and reduce methods:
          - Filter points with values less than 0.
          - Sum these negative points.
        4.2.2- Extract the last point object from the `points` array (if it exists).
        4.2.3- Create a new user object that includes:
          - All original user data.
          - The calculated sum of negative points (`redeem`).
          - The initial date of the last point (`lastInitialDate`).
    5- Log the transformed user data for debugging purposes.
    6- Assign the transformed data to the `dataSource` for use in the table.
    7- Handle any errors that occur during data retrieval by logging an error message.
*/

  getUsers(): void {
    const usersDataPath = `/projects/${this.builderService.selectedProject}/users`;

    this.db.getDatabase(usersDataPath).subscribe(
      (data: any) => {
        console.log('Fetched user data:', data);

        const userArray = Object.keys(data).map((key) => {
          const user = data[key];

          const redeem = (user.points || [])
            .filter((point: any) => point.points < 0)
            .reduce((sum: number, point: any) => sum + point.points, 0);

          const lastPoint = (user.points || []).slice(-1)[0];

          return {
            ...user,
            redeem,
            lastInitialDate: lastPoint ? lastPoint.initialDate : null,
          };
        });

        console.log('Transformed user data:', userArray);

        this.dataSource.data = userArray;
      },
      (error) => {
        console.error('Error loading user data:', error);
      }
    );
  }

  createForm() {
    this.points = this.fb.group({});
  }

  saveChanges() {
    const usersPath = `/projects/${this.builderService.selectedProject}/settings/points/users`;
    const usersData = this.points.value;

    this.db.setDatabase(usersPath, usersData).subscribe(
      (response) => {
        console.log('Points customization saved successfully!', response);
      },
      (error) => {
        console.error('Error saving points customization:', error);
      }
    );
  }

  showUserHistory(element: any) {
    if (this.selectedUser === element) {
      this.selectedUser = null;
    } else {
      this.selectedUser = element;
    }
  }

  applyFilter() {
    this.dataSource.filter = this.searchText.trim().toLowerCase();
  }
}
