<form [formGroup]="applicationSettingsForm" id="applicationSettingsWrapper" class="settingsWrapper" (ngSubmit)="onSubmit()">
  <app-stepper *ngIf="builderService.getTour()" class="settingsStepper" step="2"></app-stepper>
  <h1 *ngIf="!builderService.getTour()">{{'Application Settings' | translate }}</h1>
  <h2 class="settingsSubHeading" *ngIf="!builderService.getTour()">{{'Style Settings' | translate }}</h2>
  <div class="colorsSettingsWrapper" [class.colorsSettingsWrapperTour]="builderService.getTour()">
    <div>
      <!-- <mat-form-field appearance="outline" class="halfField hideField">
        <mat-label>Font Family</mat-label>
        <mat-select formControlName="mobile_title_family">
          <mat-option *ngFor="let fontFamily of fontFamilies" [value]="fontFamily.id">
            {{fontFamily.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="halfField hideField">
        <mat-label>Font Weight</mat-label>
        <mat-select formControlName="mobile_title_font_weight">
          <mat-option *ngFor="let fontWeight of fontWeights" [value]="fontWeight.id">
            {{fontWeight.name}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field>
        <mat-label>Font Title Color</mat-label>
        <p class="colorPickerValue">{{this.applicationSettingsData?.mobile_title_font_color}}</p>
        <label for="mobile_title_font_color" class="colorLabel">
          <input type="color" id="mobile_title_font_color" matInput class="colorPicker" name="mobile_title_font_color"
            formControlName="mobile_title_font_color" (change)="colorChange($event,'mobile_title_font_color')"
            value="{{this.applicationSettingsData?.mobile_title_font_color}}">
        </label>
      </mat-form-field> -->
        <div class="PrimaryContainer" [class.PrimaryContainerMain]="!builderService.getTour()">
          <label>{{'Primary Color' | translate }}</label>
            <div class="colorPickerContainer" [class.colorPickerContainerMain]="!builderService.getTour()">
            <p class="colorPickerValue">{{this.applicationSettingsData?.mobile_primary_color}}</p>
            <div for="mobile_primary_color" class="colorLabel">
              <input type="color" matInput class="colorPicker" name="mobile_primary_color" id="mobile_primary_color"
                formControlName="mobile_primary_color" (change)="colorChange($event,'mobile_primary_color')"
                value="{{this.applicationSettingsData?.mobile_primary_color}}">
            </div>
          </div>
        </div>
      </div>
      <div>
      <!-- <mat-form-field appearance="outline" class="halfField hideField">
        <mat-label>{{'Font Size' | translate }}</mat-label>
        <label for="mobile_base_font_size" class="colorLabel">
          <input matInput formControlName="mobile_base_font_size" placeholder="22" type="number"
            id="mobile_base_font_size" value="{{this.applicationSettingsData?.mobile_base_font_size}}">
        </label>
      </mat-form-field>
      <mat-form-field appearance="outline" class="halfField hideField">
        <mat-label>Text Font Weight</mat-label>
        <mat-select formControlName="mobile_text_font_weight">
          <mat-option *ngFor="let textFontWeight of textFontWeights" [value]="textFontWeight.id">
            {{textFontWeight.name}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <div class="PrimaryContainer" [class.PrimaryContainerMain]="!builderService.getTour()">
          <label>{{'Secondary Color' | translate }}</label>
          <div class="colorPickerContainer" [class.colorPickerContainerMain]="!builderService.getTour()">
            <p class="colorPickerValue">{{this.applicationSettingsData?.mobile_text_color}}</p>
            <div for="mobile_text_color" class="colorLabel">
              <input type="color" matInput class="colorPicker" name="mobile_text_color"
                formControlName="mobile_text_color" (change)="colorChange($event,'mobile_text_color')" id="mobile_text_color"
                value="{{this.applicationSettingsData?.mobile_text_color}}">
            </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <mat-form-field>
        <mat-label>Text Color</mat-label>
        <p class="colorPickerValue">{{this.applicationSettingsData?.mobile_secondary_color}}</p>
        <label for="mobile_secondary_color" class="colorLabel">
          <input type="color" matInput class="colorPicker" name="mobile_secondary_color"
            formControlName="mobile_secondary_color" id="mobile_secondary_color"
            (change)="colorChange($event,'mobile_secondary_color')"
            value="{{this.applicationSettingsData?.mobile_secondary_color}}">
        </label>
      </mat-form-field>
    </div> -->
    <button *ngIf="this.builderService.getTour()" class="stacksBtn tourBtn blue" type="button" (click)="applicationSettingsNext()">
      {{'Next' | translate }}
    </button>
  </div>
  <div [class.colorsSettingsWrapperMain]="!builderService.getTour()" *ngIf="!builderService.getTour()">
    <!-- <h2 class="settingsSubHeading" style="margin-top: -40px;">Facebook settings</h2>
    <p class="sectionHint">To know how those fields works <a
        href="https://stacksmarket.co/knowledge-base/activate-facebook-login-on-your-android-mobile-application/" target="_blank">please go
        here</a></p>
    <ul>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>Facebook App ID</mat-label>
          <input matInput formControlName="facebook_app_id" placeholder="App ID" type="text"
            value="{{this.applicationSettingsData?.facebook_app_id}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Facebook Hash ID</mat-label>
          <input matInput formControlName="facebook_hash_id" placeholder="" type="text" readonly
            value="{{this.applicationSettingsData?.facebook_hash_id}}">
        </mat-form-field>
        <p>This is Automatically Generated after you add the Facebook App ID and App Name and Build your application,
          Copy this ID and paste it on Facebook App</p>
      </li>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>Facebook App Name</mat-label>
          <input matInput formControlName="facebook_app_name" placeholder="Facebook App Name" type="text"
            value="{{this.applicationSettingsData?.facebook_app_name}}">
        </mat-form-field>
      </li>
    </ul> -->
  </div>
  
  
  <!-- <h2 class="settingsSubHeading" style="margin-top: -40px;">Google Admob</h2> -->
   <!-- <p class="sectionHint">To know how those fields works <a
       href="https://stacksmarket.co/knowledge-base/activate-push-notifications-on-your-mobile-application/" target="_blank">please go
       here</a></p> -->
  <!-- <ul>
    <li>
      <mat-form-field appearance="outline">
        <mat-label>Android App ID</mat-label>
        <input matInput formControlName="gmob_android_app_id" placeholder="Android Ad ID" type="text"
          value="{{this.applicationSettingsData?.gmob_android_app_id}}">
      </mat-form-field>
    </li>
    <li>
      <mat-form-field appearance="outline">
        <mat-label>IOS App ID</mat-label>
        <input matInput formControlName="gmob_ios_app_id" placeholder="IOS Ad ID" type="text"
          value="{{this.applicationSettingsData?.gmob_ios_app_id}}">
      </mat-form-field>
    </li>
  </ul> -->
  <!-- <h2 class="settingsSubHeading" style="margin-top: -40px;">Taxonomy Filteration</h2>
  <p class="sectionHint">To know how those fields works <a
      href="https://stacksmarket.co/knowledge-base/activate-push-notification/" target="_blank">please go
      here</a></p>
  <ul>
    <li>
      <mat-form-field appearance="outline">
        <mat-label>Taxonomy Filteration</mat-label>
        <input matInput formControlName="android_server_key" placeholder="App ID" type="text"
          value="{{this.applicationSettingsData?.android_server_key}}">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Google Service JSON File</mat-label>
        <input matInput formControlName="facebook_hash_id" placeholder="" type="text" readonly
          value="{{this.applicationSettingsData?.facebook_hash_id}}">
      </mat-form-field>
    </li>
  </ul> -->
</form>