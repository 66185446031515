import { BuilderService } from 'src/app/builder-services/builder.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { SettingsService } from 'src/app/e-commerce/settings/settings.service';

interface TableRow {
  [key: string]: any; 
  Currency?: string;
  En: string;
}

@Component({
  selector: 'app-txt-translations',
  templateUrl: './txt-translations.component.html',
  styleUrl: './txt-translations.component.scss'
})
export class TxtTranslationsComponent implements OnInit {
  currency: any;
  currentPageIndex: number = 0;
  displayedColumns: string[] = [];
  editingCell: { row: TableRow | null, lang: string | null } = { row: null, lang: null };
  filteredData: TableRow[] = [];
  languages: { title: string }[] = [];
  pagedTableData: MatTableDataSource<TableRow> = new MatTableDataSource<TableRow>([]);
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  searchText: string = '';
  tableData: TableRow[] = [];
  txtTranslations!: FormGroup;
  productProgress: boolean = false;

  constructor(
    private BuilderService: BuilderService, 
    private db: DatabaseService,
    private http: HttpClient,
    private settingsService: SettingsService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.txtTranslations = this.fb.group({
      languages: this.fb.array([])
    });
    this.loadLanguages();
    this.getCurrency();
  }

  updatePageData(pageIndex: number): void {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedTableData.data = this.filteredData.slice(startIndex, endIndex);
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.currentPageIndex = event.pageIndex;
    this.updatePageData(this.currentPageIndex);
  }

  getTranslate(): void {
    const project_id = this.BuilderService.selectedProject;
    if (!this.languages.length) {
      console.log('No languages available.');
      return;
    }

    let dataLoadedCount = 0;
    const allData: { [key: string]: any } = {};

    this.languages.forEach(lang => {
      const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/texts`;
      this.db.getDatabase(uniquePath).subscribe({
        next: (translationsData: any) => {
          if (translationsData && typeof translationsData === 'object') {
            // Aggregate data from each language
            for (const key in translationsData) {
              if (translationsData.hasOwnProperty(key)) {
                if (!allData[key]) {
                  allData[key] = { En: key };  // Assume 'En' key is your reference
                }
                allData[key][lang.title] = translationsData[key].value;
              }
            }
            // Check if all languages are loaded
            dataLoadedCount++;
            if (dataLoadedCount === this.languages.length) {
              this.tableData = Object.values(allData); // Convert aggregated data to array
              this.addCurrencyRow();
              this.filterData();
              this.updatePageData(this.currentPageIndex);
            }
          } else {
            console.error(`Translations data for ${lang.title} is not in the expected format:`, translationsData);
          }
        },
        error: (error) => console.error(`Failed to fetch translations for ${lang.title}:`, error)
      });
    });
  }

  loadData() {
    this.http.get<string[]>('assets/i18n/txtTranslation.json').subscribe(
      (data: string[]) => {
        // Map the fetched data to your tableData structure, only updating non-existing entries
        const newData: TableRow[] = data.map(text => ({ En: text }));
        const updatedData = newData.map(newRow => {
          const existingRow = this.tableData.find(row => row.En === newRow.En);
          return existingRow ? existingRow : newRow; // Preserve existing row data if it exists
        });

        this.tableData = updatedData;
        this.addCurrencyRow();
        this.filterData();
        this.updatePageData(this.currentPageIndex);
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  toggleEdit(row: TableRow, lang: string): void {
    if (this.editingCell.row === row && this.editingCell.lang === lang) {
      this.editingCell = { row: null, lang: null };
    } else {
      this.editingCell = { row, lang };
    }
  }

  filterData(): void {
    if (this.searchText) {
      this.filteredData = this.tableData.filter(row =>
        row.En.toLowerCase().includes(this.searchText.toLowerCase()) ||
        (row.Currency && row.Currency.toLowerCase().includes(this.searchText.toLowerCase())) ||
        this.languages.some(lang => row[lang.title]?.toLowerCase().includes(this.searchText.toLowerCase()))
      );
    } else {
      this.filteredData = [...this.tableData]; // Copy all data if no search text
    }
    this.updatePageData(0); // Reset to the first page after filtering
  }

  loadLanguages(): void {
    let project_id = this.BuilderService.selectedProject;
    const uniquePath = `/projects/${project_id}/translations/languages`;

    this.db.getDatabase(uniquePath).subscribe(
      (languagesData: any) => {
        console.log('Languages Data:', languagesData);

        if (languagesData && typeof languagesData === 'object') {
          // Transform languagesData object into an array of language objects
          this.languages = Object.keys(languagesData).map(key => ({
            title: key,
            translation: languagesData[key]
          }));

          // Find the index of "English" language
          const englishIndex = this.languages.findIndex(lang => lang.title === 'English');

          // If "English" is found and it's not already the first element
          if (englishIndex !== -1 && englishIndex !== 0) {
            // Move "English" to the beginning of the array
            const englishLanguage = this.languages.splice(englishIndex, 1)[0];
            this.languages.unshift(englishLanguage);
          }
          this.displayedColumns = [...this.languages.map(lang => lang.title)];
          this.getTranslate();
        } else {
          console.error('Languages data is not in the expected format:', languagesData);
        }
      },
      (error) => {
        console.error('Error fetching languages data:', error);
      }
    );
  }

  saveChanges(): void {
    this.productProgress = true;
    let project_id = this.BuilderService.selectedProject;
    interface Translations {
      [key: string]: { value: string };
    }
    // Iterate over each row in tableData and update local values if needed
    this.tableData.forEach(row => {
      this.languages.forEach(lang => {
        row[lang.title] = row[lang.title] || '';
      });
    });

    this.languages.forEach(lang => {
      let translationsObject: Translations = {}; // Define the type of translationsObject

      // Create an object where key is the English term and value is the nested translation
      this.tableData.forEach(row => {
        const key = this.generateValidKey(row.En);

        // Set the translation using the valid key
        if (lang.title === 'English') {
          // If the language is English, set the value to be equal to the key
          translationsObject[key] = { value: key.replace(/-/g, ' ') };
        } else {
          // Otherwise, set the value normally
          translationsObject[key] = { value: row[lang.title] };
        }
      });

      const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/texts`;

      this.db.setDatabase(uniquePath, translationsObject).subscribe({
        next: (response) => {
          console.log(`Translations for ${lang.title} saved:`, response)
          this.productProgress = false;
        },
        error: (error) => {
          this.productProgress = false;
          console.error(`Failed to save translations for ${lang.title}:`, error)
        }
      });
    });
  }

  generateValidKey(input: string): string {
    return input.replace(/[#$\/\[\] ]/g, ' ');
  }

  getCurrency() {
    this.settingsService.getCurrencySettings().subscribe(
      data => {
        this.currency = data;
        this.addCurrencyRow();
      },
      error => {
        console.error('Error fetching currency data:', error);
      }
    );
  }

  addCurrencyRow() {
    if (this.currency) {
      const currencySymbol = this.currency;
      const currencyRow: TableRow = { En: currencySymbol };

      // Add the currency translations for each language
      this.languages.forEach(lang => {
        const uniquePath = `/projects/${this.BuilderService.selectedProject}/translations/languages/${lang.title}/texts/${currencySymbol}`;
        this.db.getDatabase(uniquePath).subscribe({
          next: (currencyTranslation: any) => {
            currencyRow[lang.title] = currencyTranslation.value;
            if (!this.tableData.some(row => row.En === currencyRow.En)) {
              this.tableData.push(currencyRow);
            }
            this.filterData(); // Apply search filter if there's any
          },
          error: (error) => console.error(`Failed to fetch currency translation for ${lang.title}:`, error)
        });
      });
    }
  }
}