<mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
<div class="productsWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <h1>{{'Translations' | translate }}</h1>
      <div class="headerBtns">
        <button (click)="loadData()" class="stacksBtn blue">{{'Fetch New Strings' | translate }}</button>
        <input type="text" [(ngModel)]="searchText" (keyup)="filterData()" [placeholder]="'Search by English words' | translate">
      </div>
    </div>

    <table mat-table [dataSource]="pagedTableData" class="mat-elevation-z8" matSort>
      <!-- Language Columns -->
      <ng-container class="title" *ngFor="let lang of languages" [matColumnDef]="lang.title">
        <th mat-header-cell *matHeaderCellDef>{{ lang.title }}</th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="lang.title !== 'English'; else englishColumn">
            <div *ngIf="editingCell.row !== row || editingCell.lang !== lang.title">
              <div class="beforeEdit">
                {{ row[lang.title] }}
                <span class="material-icons material-icons-outlined editIcon" (click)="toggleEdit(row, lang.title)">edit</span>
              </div>
            </div>
            <div *ngIf="editingCell.row === row && editingCell.lang === lang.title">
              <input type="text" class="translateTextInput" [(ngModel)]="row[lang.title]" placeholder="Enter translation" (blur)="toggleEdit(row, lang.title)">
            </div>
          </ng-container>
          <ng-template #englishColumn>
            <div>
              {{ row.En === 'currencySymbol' ? row.Currency : row.En }}
            </div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
    <mat-paginator [length]="tableData.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
            [pageIndex]="currentPageIndex" (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>
<form [formGroup]="txtTranslations" id="txtTranslations" (ngSubmit)="saveChanges()">
</form>