<div class="pointsContainer" [formGroup]="points">
  <div class="pointPart pointPartText tableWrapper">
    <h3>{{ "Users List" | translate }}</h3>
    <div class="tableHeader">
      <h4>{{ "List" | translate}}</h4>
      <div class="headerBtns">
        <form class="searchBar">
          <input
            type="text"
            matInput
            placeholder="{{ 'Search' | translate }}"
            id="searchInput"
            [(ngModel)]="searchText"
            (ngModelChange)="applyFilter()"
            name="searchText"
            data-search
          />
        </form>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 userTable" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{ "ID" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.uid }}</td>
      </ng-container>

      <!-- User Name Column -->
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef>{{ "User Name" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ "Email" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <!-- Points Column -->
      <ng-container matColumnDef="points">
        <th mat-header-cell *matHeaderCellDef class="green">{{ "Points" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.totalPoints }}</td>
      </ng-container>

      <!-- Redeem Column -->
      <ng-container matColumnDef="redeem">
        <th mat-header-cell *matHeaderCellDef class="red">{{ "Redeem" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.redeem }}</td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{ "Date" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastInitialDate }}
        </td>
      </ng-container>

      <!-- Transactions History Column -->
      <ng-container matColumnDef="transactionsHistory">
        <th mat-header-cell *matHeaderCellDef>
          {{ "Transactions History" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="history">
          <button mat-icon-button (click)="showUserHistory(element)">
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Table Header and Rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="selectedUser" class="selectedUser">
      <table
        mat-table
        [dataSource]="selectedUser.points"
        class="mat-elevation-z8"
      >
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ "Date" | translate }}</th>
          <td mat-cell *matCellDef="let point">{{ point.initialDate }}</td>
        </ng-container>

        <!-- Redeemed Column -->
        <ng-container matColumnDef="redeemed">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Redeemed" | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            {{ point.points < 0 ? point.points : "0" }}
          </td>
        </ng-container>

        <!-- Remaining Column -->
        <ng-container matColumnDef="remaining">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Remaining" | translate }}
          </th>
          <td mat-cell *matCellDef="let point">
            {{ point.points > 0 ? point.points : "0" }}
          </td>
        </ng-container>

        <!-- Table Header and Rows -->
        <tr
          mat-header-row
          *matHeaderRowDef="['date', 'redeemed', 'remaining']"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['date', 'redeemed', 'remaining']"
        ></tr>
      </table>
    </div>
  </div>
</div>
<form id="points" (ngSubmit)="saveChanges()"></form>
