import { BuilderService } from 'src/app/builder-services/builder.service';
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-category-translation',
  templateUrl: './category-translation.component.html',
  styleUrl: './category-translation.component.scss'
})
export class CategoryTranslationComponent implements OnInit {

  categories: any[] = []; 
  displayedColumns: string[] = [];
  ELEMENT_DATA: any[] = [];
  languages: { title: string }[] = [];
  pagedTableData: any[] = [];
  pageSize: number = 5;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  categoryTranslations!: FormGroup;
  productProgress: boolean = false;

  constructor(
    private db: DatabaseService,
    private BuilderService: BuilderService,
    private categoriesService: CategoriesService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.categoryTranslations = this.fb.group({
      languages: this.fb.array([])
    });
    this.loadLanguages().then(() => {
      this.getCategoryNames();
    }).catch(error => console.error("Initialization failed:", error));
  }

  updatePageData(pageIndex: number) {
    const startIndex = pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedTableData = this.ELEMENT_DATA.slice(startIndex, endIndex);
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.updatePageData(event.pageIndex);
  }

  getCategoryNames(): void {
    this.categoriesService.getCategories().subscribe({
      next: (categoriesObject) => {
        this.ELEMENT_DATA = Object.values(categoriesObject);
        this.updatePageData(0);
        console.log('Categories loaded successfully.');
        this.getTranslate(); // Call to load translations right after categories are loaded
        this.updateDisplayedColumns();
      },
      error: (error) => {
        console.error('Error fetching categories:', error);
      }
    });
  }

  toggleEdit(cat: any, langTitle: string): void {
    this.ELEMENT_DATA.forEach(c => {
      if (c !== cat) {
        c.editingLang = null;
      }
    });
    cat.editingLang = langTitle;
  }

  getTranslate(): void {
    const project_id = this.BuilderService.selectedProject;
    this.ELEMENT_DATA.forEach(category => {
      this.languages.forEach(lang => {
        const path = `/projects/${project_id}/translations/languages/${lang.title}/Categories/${category.categoryName}`;
        this.db.getDatabase(path).subscribe({
          next: (response: any) => {
            if (response && response.value) {
              category[lang.title] = response.value;
            }
          },
          error: (error) => console.error(`Failed to load translation for ${category.categoryName} in ${lang.title}:`, error)
        });
      });
    });
  }

  loadLanguages(): Promise<void> {
    return new Promise((resolve, reject) => {
      const project_id = this.BuilderService.selectedProject;
      const path = `/projects/${project_id}/translations/languages`;
      this.db.getDatabase(path).subscribe({
        next: (languagesData: any) => {
          this.languages = this.formatLanguages(languagesData);
          this.sortLanguages('English');
          resolve();
        },
        error: (error) => {
          console.error('Error fetching languages:', error);
          reject(error);
        }
      });
    });
  }

  formatLanguages(languagesData: any): any[] {
    return Object.keys(languagesData).map(key => ({ title: key, ...languagesData[key] }));
  }

  sortLanguages(primaryLanguage: string): void {
    const index = this.languages.findIndex(lang => lang.title === primaryLanguage);
    if (index > -1) {
      const [primaryLang] = this.languages.splice(index, 1);
      this.languages.unshift(primaryLang);
    }
  }

  saveChanges(): void {
    this.productProgress = true;
    const project_id = this.BuilderService.selectedProject;
    // First, delete the old data
    this.languages.forEach(lang => {
      this.ELEMENT_DATA.forEach(category => {
        const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/Categories/${category.categoryName}`;
        // Delete the translation data
        this.db.removeDatabaseEndpoint(uniquePath).subscribe({
          next: (response) => console.log(`Translation for ${category.categoryName} in ${lang.title} deleted:`, response),
          error: (error) => console.error(`Failed to delete translation for ${category.categoryName} in ${lang.title}:`, error)
        });
      });
    });
    // Then, add the new data
    this.ELEMENT_DATA.forEach(category => {
      this.languages.forEach(lang => {
        let value;
        if (lang.title === 'English') {
          value = category.categoryName.replace(/-/g, ' ');
        } else {
          value = category[lang.title] || ''; // Use input value or an empty string if undefined
        }
        const translationObject = {
          value: value
        };
        const uniquePath = `/projects/${project_id}/translations/languages/${lang.title}/Categories/${category.categoryName}`;
        // Save the new translation data
        this.db.setDatabase(uniquePath, translationObject).subscribe({
          next: (response) => {
            console.log(`Translation for ${category.categoryName} in ${lang.title} saved:`, response)
            this.productProgress = false;
          },
          error: (error) => {
            console.error(`Failed to save translation for ${category.categoryName} in ${lang.title}:`, error)
            this.productProgress = false;
          }
        });
      });
    });
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = this.languages.map(lang => lang.title);
  }
}
