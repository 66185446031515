import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { LoginService } from 'src/app/login/login.service';
import { AppSettingsService } from '../services/app-settings.service';
import { AndroidbuildService } from 'src/app/build/android-build/androidbuild.service';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  selectedFile!: ImageSnippet;
  showProgress = false;
  settingsForm = this.formBuilder.group({
    application_name: '',
    package_name: '',
    ipa_sku_number: '',
    application_description: 'Description',
    icon: '',
    splash_screen: '',
    release_app: '',
  });

  fileToUpload: File | null = null;

  generalSettingsData : any = {};
  constructor(
    private formBuilder: FormBuilder,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginService,
    private androidService: AndroidbuildService
  ) { 
    this.builderService.showUpdateProgress = true;
    // Wait for sometime till the project id is retrieved from the server if it is not available yet
    let project_id = this.builderService.getSelectedProject();
    if(!project_id) {
      setTimeout(() => {
        this.getInitialSettings();
      },2000);
    } else {
      this.getInitialSettings();
    }
  }

  getInitialSettings = () => {
    let project_id = this.builderService.getSelectedProject();
    this.generalSettingsData = this.appSettingsService.getGeneralSettings(project_id).subscribe((settings: any) => {
      if (settings !== null) {
        this.settingsForm.patchValue({ application_description: 'Description' });
        this.settingsForm.patchValue({ release_app: settings.release_app });
      }

      this.generalSettingsData = settings !== null ? settings : {};
      this.builderService.showUpdateProgress = false;
    }, (err) => {
      console.log(err);
    });
  }
  ngOnInit(): void {
  }

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true;
    let icon = this.settingsForm.value.icon;
    let splash_screen = this.settingsForm.value.splash_screen;
    /**
     * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
     */
      let newSubmittedData:any = {};
      let submittedData:any = this.settingsForm.value;
      let retrievedData = this.generalSettingsData;
      for (var k in submittedData) {
        if (submittedData[k] != "") {
          newSubmittedData[k] = submittedData[k];
        } else {
          newSubmittedData[k] = retrievedData[k];
        }
      }
    /**
     * Step 1 Submit the Icons and Splash Screens
     */
    if (!icon ) {
      newSubmittedData['icon'] = this.generalSettingsData.icon;
    }
    if (!splash_screen) {
      newSubmittedData['splash_screen'] = this.generalSettingsData.splash_screen;
    }


    
    this.appSettingsService.setGeneralSettings(this.builderService.selectedProject, newSubmittedData).subscribe( (response) => {
      this.builderService.showUpdateProgress = false;
    }, (err) => {

    });
  }

  processFile(imageInput: any, type: string, minWidth: number, minHeight: number) {
    const file: File = imageInput.target.files[0];
    const reader = new FileReader();
    this.builderService.showUpdateProgress = true;
    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.appSettingsService.uploadImage(this.selectedFile.file, this.builderService.selectedProject, type ).subscribe(
        (res) => {
          if (type == 'icon' || type == 'splash_screen') {
            /**
             * Validate the image width and height
             */
              const img = new Image();
              let $this = this;
              img.src = res;
              img.addEventListener("load", function () {
                if (this.width < minWidth || this.height < minHeight) {
                  alert("Min image Dimensions = " + minWidth + " x " + minHeight + ", Uploaded Dimensions = " + this.width + " x " + this.height);
                } else {
                  $this.generalSettingsData[type] = res;
                }

              });
          } else {
            this.generalSettingsData[type] = res;
          }
          
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading file, please contact support");
        })
    });

    reader.readAsDataURL(file);
  }

  generalSettingsNext = () => {
    this.onSubmit();
    if( this.builderService.projectAccess == 'webview' ) {
      this.builderService.setTour(false).subscribe((response) => {
        this.router.navigate(['/contentsettings'], { relativeTo: this.route });
      }, (err) => {
  
      });
      
    } else {
      this.router.navigate(['/site-type'], { relativeTo: this.route });
    }
  }
}