import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ProductsService } from '../products.service';

export interface productsTableColumns {
  id: string;
  image: string;
  name: string;
  sku: string;
  stock: string;
  price: string;
  date: string;
  action: string;
  checked?: boolean;
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatTable)
  table!: MatTable<productsTableColumns>;

  @ViewChild(MatSort, { static: true })
  matSort!: MatSort;
  @ViewChild('selectAllCheckbox') selectAllCheckbox!: MatCheckbox;

  products:any = {};
  pageLoaded = false;
  public productsColumns: string[] = [];
  public dataSource: any = [];
  products_dates: any = [];
  public elements: any[] = [];
  hasCheckedItems: boolean = false;
  products_data_counts: any = [];
  mobile = false;
  ELEMENT_DATA: productsTableColumns[] = [];

  constructor(private productsService: ProductsService, public builderService: BuilderService,
    private router: Router, private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private _liveAnnouncer: LiveAnnouncer) { 
    }

  ngOnInit(): void {
    this.getAllData();

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  getAllData = () => {
    this.productsService.getProducts().subscribe((response_products: any) => {
      this.pageLoaded = true;
      this.builderService.showUpdateProgress = true;
      this.productsColumns = ['image', 'name', 'sku', 'stock', 'price', 'date', 'action'];
      this.products = response_products;
      // this.dataSource.sort = this.matSort;
      this.ELEMENT_DATA = [];
      this.renderProducts(this.products).subscribe((response: any) => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.matSort;
        if (response) {
          this.table.renderRows();
        }
        this.builderService.showUpdateProgress = false;
      });
    })
  }

  toggleSelectAllItems() {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = startIndex + this.paginator.pageSize;
    const currentPageData = this.dataSource.data.slice(startIndex, endIndex);

    const allItemsChecked = currentPageData.every((item: productsTableColumns) => item.checked);
    currentPageData.forEach((item: productsTableColumns) => (item.checked = !allItemsChecked));
    this.hasCheckedItems = !allItemsChecked;
  }

  onCheckboxItem() {
    this.hasCheckedItems = this.dataSource.data.some((item: productsTableColumns) => item.checked);
    this.cdr.detectChanges();  // Manually trigger change detection
  }
  deleteCheckedItems() {
    const checkedItems = this.ELEMENT_DATA.filter(element => element.checked);
    if (checkedItems.length === 0) {
      console.log('No items selected for deletion.');
      return;
    }
  
    const itemNames = checkedItems.map(item => item.name).join(', ');
  
    const confirmation = window.confirm(`Are you sure you want to delete the selected items?\nItems: ${itemNames}`);
    this.builderService.showUpdateProgress = true;
    if (confirmation) {
      console.log('Deleting items:', checkedItems);
      for (let index = 0; index < checkedItems.length; index++) {
        const element = checkedItems[index];
        this.deleteProduct(element.id, element.image, false);
      }
      // Uncheck all checkboxes after deletion
      this.ELEMENT_DATA.forEach(item => item.checked = false);
      this.selectAllCheckbox.checked = false; // Uncheck the master checkbox
      this.hasCheckedItems = false;
    } else {
      console.log('Deletion canceled.');
    }
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    setTimeout(() => {
      this.paginator.page.subscribe(() => this.uncheckAllCheckboxes());
    }, 2000);
  }

  uncheckAllCheckboxes() {
    this.ELEMENT_DATA.forEach(item => item.checked = false);
    this.selectAllCheckbox.checked = false;
    this.hasCheckedItems = false;
    this.cdr.detectChanges();
  }

  /**
   * Fill products Table
   */
  //  getproducts = () => {
   
  //   ELEMENT_DATA = [];
  //   let cloudSubs = localStorage.getItem('cloudAllSubs');
  //   if(cloudSubs) {
  //     this.renderproducts(JSON.parse(cloudSubs));
  //   } else {
  //   }
  // }
  /**
   * Renders the subscriptions data
   */
  renderProducts = (result: any): Observable<any> => {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false;
      this.products_data_counts = [];
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const element = result[key];
          this.products_data_counts.push(element.count);
          let date: any = element.date ? String(element.date) : '';
          let price: any = element.salePrice ? element.regularPrice + ' / ' + element.salePrice : element.regularPrice

          this.ELEMENT_DATA.push(
            {
              id: element.Id,
              image: element.imageUrl,
              name: element.productName,
              sku: element.productSKU,
              stock: element.productStock,
              price: price,
              date: date ? date.replaceAll("/", "-") : '',
              action: '',
            },
          );
        }
      }

      this.dataSource = new MatTableDataSource<productsTableColumns>(this.ELEMENT_DATA);
      setTimeout(() => {
        observer.next(); // Notify the observer that the operation is complete
        observer.complete(); // Complete the Observable
      }, 100);
    });
  }

  applySubFilter() {
    const filterValue = this.searchInput.trim().toLowerCase();

    // Filter based on the 'name' property
    this.dataSource.filterPredicate = (data: productsTableColumns, filter: string) => {
      return data.name.toLowerCase().includes(filter);
    };

    this.dataSource.filter = filterValue;
  }

  searchInput: string = '';

  // Add a method to handle the input change event
  onSearchInputChange(event: Event) {
    this.searchInput = (event.target as HTMLInputElement).value;
    this.applySubFilter();  // Provide the 'event' argument to the method
  }

  deleteProduct = (id: string, imageName: string, singleItem = true) => {
    if(singleItem) {
      const confirmation = confirm('Are you sure you want to delete this product?');
      if(!confirmation) {
        return;
      }
    }
    this.productsService.removeProduct(id).subscribe(() => {
      if(imageName) {
        this.builderService.deleteImage(imageName).subscribe(() => {
          this.getAllData();
        },(error) => {
          this.getAllData();
        })
      } else {
        this.getAllData();
      }
    });
  }

  editProduct = (id: string) => {
    // let product_link = ;
    this.router.navigate([`/products/new-product/${id}`], { relativeTo: this.route });
  }
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
