import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../orders.service';
import { SettingsService } from '../../settings/settings.service';

@Component({
  selector: 'app-single-order',
  templateUrl: './single-order.component.html',
  styleUrls: ['./single-order.component.scss']
})
export class SingleOrderComponent implements OnInit {

  public orderIdFromRoute: any = [];
  mobile = false;
  orderData:any;
  currency = '';
  possibleStatuses = [
    'Pending',
    'Order Received',
    'Processing',
    'Completed',
    'Cancelled',
    'Refunded',
    'Failed',
    'On-Hold',
    'Trash'
  ];
  selectedStatus = 'Order Received';
  allowUpdateStatus = false;
  constructor(private route: ActivatedRoute, public orderService: OrdersService, private settingsService: SettingsService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.orderIdFromRoute = routeParams.get('orderId');
    this.settingsService.getSettings().subscribe((response: any) => {
      this.currency = response.currency;
    })
    if (this.orderIdFromRoute) {
      this.orderService.getOrder(this.orderIdFromRoute).subscribe((orderData: any) => {
        this.orderData = orderData[0];
        this.selectedStatus = this.orderData.status;
        for (const key in this.orderData.address) {
          if (Object.prototype.hasOwnProperty.call(this.orderData.address, key)) {
            const element = this.orderData.address[key];
            this.orderData.address = element;
          }
        }
      })

    }

    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
  }

  updateStatus(status: string) {
    this.orderService.updateOrderStatus(this.orderIdFromRoute, status, this.orderData.user_id).subscribe((response: any) => {
      this.orderData.status = status;
      this.allowUpdateStatus = false;
    })
  }

}
